import { Provider } from 'react-redux'
import { HashRouter as Router } from 'react-router-dom';
import { init } from '@rematch/core';
import zhCN from 'antd/locale/zh_CN';
import { ConfigProvider } from 'antd';

import Layouts from 'layouts/index';

import * as models from './models';

const store = init({models});

const App = () => (
  <Provider store={store}>
    <ConfigProvider
      locale={zhCN}
      theme={{
        token: {
          colorPrimary: '#007FFF',
          colorLink: '#007FFF',
        },
      }}
    >
      <Router>
        <Layouts />
      </Router>
    </ConfigProvider>
  </Provider>
);
export default App;
