import ppo from 'ppo';
import { TopicItem } from 'configs/constant/corpus';

import { LOGIN_TYPE } from './../configs/constant/common';

// 从一个数组中获取指定value的key
export const getNameFromArr = (arr: any, value: string | number, key = 'label', valueKey = 'value') => {
  const res = arr ? arr.find((item: any) => item[valueKey] === value) : null;
  return res?.[key] !== undefined ? res?.[key] : '';
};

export const shuffleArray = (array: any) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

export const scrollToDistance = (element: any, distance: any) => {
  // const distance = element.scrollHeight - element.scrollTop - element.clientHeight;
  const duration = 500; // 滚动时间（毫秒）
  const start = performance.now();

  function step(timestamp: any) {
    const progress = Math.min((timestamp - start) / duration, 1);
    const distanceScroll = distance * progress;
    element.scrollTop = element.scrollTop + distanceScroll;

    if (progress < 1) {
      window.requestAnimationFrame(step);
    }
  }

  window.requestAnimationFrame(step);
};

export const isNul = (tmp: number | string | null | undefined) =>
  typeof tmp === 'undefined' || tmp === null || tmp.toString().trim() === '';

/* 是否正式环境 */
export const IS_PROD = process.env.NODE_ENV?.toLowerCase() === 'production';

/**
 * （保留n位小数,得到字符串）
 */
export const formatFloat = (value: number, n = 2, down?: boolean) => {
  let result = '0';
  try {
    if (!value) {
      return result;
    }
    if (down) {
      // 向下保留n位小数
      // eslint-disable-next-line no-restricted-properties
      result = String(Math.floor(value * Math.pow(10, n)) / Math.pow(10, n));
    } else {
      // 四舍五入保留n位小数
      // eslint-disable-next-line no-restricted-properties
      result = ((value * Math.pow(10, n)) / Math.pow(10, n)).toFixed(n);
    }
    return result;
  } catch (error) {
    return result;
  }
};

export const generateRandomString = (length: number) => {
  const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
};

export const getColumnFilter = (columnMap: { [x: string]: { options: any } }, key: string) => {
  const optionsMap = columnMap[key].options;
  const optionsFilterArr: { value: string; text: any }[] = [];
  Object.keys(optionsMap).forEach((key) => {
    optionsFilterArr.push({
      value: key,
      text: optionsMap[key],
    });
  });
  return optionsFilterArr;
};

export const filterOption = (input: string, option?: { label: string; value: string }) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

export const pickElementsWithRatio = (arr: TopicItem[], ratio = 0) => {
  const numberOfElementsToPick = Math.round(arr.length * ratio * 0.01);
  const indexArr = Array.from({ length: arr.length }, (_, i) => i);
  const pickIndexArr = [];
  const pickedElements: TopicItem[] = [];
  const shuffledArray = indexArr.sort(() => Math.random() - 0.5); // 随机打乱数组顺序
  for (let i = 0; i < numberOfElementsToPick; i++) {
    pickIndexArr.push(shuffledArray[i]);
  }
  for (let i = 0; i < pickIndexArr.length; i++) {
    pickedElements.push(arr[i]);
  }
  return pickedElements?.length === 0 ? [arr[shuffledArray[0]]] : pickedElements;
};

const localUserRtx = 'jiangyinsu';

export const getUserKey = () => {
  // const isWxLogin = getIsWxLogin();
  // const isPswLogin = getIsPswLogin();
  const userId = ppo.getCookie('user-id');
  return window.location.hostname === 'localhost' ? localUserRtx : userId;
  // isWxLogin ? localStorage.getItem('UG_CAIDATA_OPENID') : isPswLogin ? localStorage.getItem('UG_CAIDATA_PSW_USER_ID') : user;
};

// 外部登录
export const getIsExternalLogin = (accountType: string) => {
  const isWxLogin = getIsWxLogin(accountType);
  const isPswLogin = getIsPswLogin(accountType);
  return isWxLogin || isPswLogin;
};

// 修改这里可切换本地wx登录方式
export const getLoginAccountType = () => {
  return window.location.hostname === 'localhost'
    ? LOGIN_TYPE.rtx
    : localStorage.getItem('UG_CAIDATA_LOGIN_TYPE') || '';
};

export const getIsWxLogin = (accountType: string) => {
  return accountType === LOGIN_TYPE.wx;
  // return window.location.hostname === 'caidata.qq.com' || false;
};

export const getIsRtxLogin = (accountType: string) => {
  // 本地不能调成rtx登录
  return accountType === LOGIN_TYPE.rtx;
  // 如果这样，那么woa的域名展示不了登录页面
  // return window.location.hostname === 'caidata.woa.com' || window.location.hostname === 'testcaidata.woa.com' || false;
};
export const getIsPswLogin = (accountType: string) => {
  // return localStorage.getItem('UG_CAIDATA_LOGIN_TYPE') === LOGIN_TYPE.rtx;
  return accountType === LOGIN_TYPE.psw;
};
export const getIsExistLoginType = () => {
  // return localStorage.getItem('UG_CAIDATA_LOGIN_TYPE') === LOGIN_TYPE.rtx;
  return Object.values(LOGIN_TYPE).includes(getLoginAccountType());
};
export const defaultUserName = '***';

export const getWoaDomain = () => {
  return window.location.hostname;
};

export const getIsQqDomain = () => {
  return window.location.hostname.includes('qq.com');
};

export const getCaiDomain = () => {
  if (getIsQqDomain()) {
    return getCaiQQDomain();
  }
  return getCaiWoaDomain();
};

export const getCaiQQDomain = () => {
  return IS_PROD ? 'ugactt.qq.com' : 'ugactt.qq.com';
};

export const getCaiWoaDomain = () => {
  return IS_PROD ? 'cai.woa.com' : 'testcai.woa.com';
};

// 判断字符还是数组对象的json
export const isJsonObjOrArrValue = (inputString: string) => {
  try {
    const parsedData = JSON.parse(inputString);
    if (parsedData && typeof parsedData === 'object') {
      // 数组或对象
      return true;
    } else {
      // 数字
      return false;
    }
  } catch (error) {
    // 字符串
    return false;
  }
};

// 校验json的合法性
export const checkIsValidJson = (jsonStr: string | null) => {
  // 校验ext，不是json格式抛出异常-先注释-不能修改ext
  let isValid = true;
  let compressionJson: any = null;
  try {
    compressionJson = jsonStr ? JSON.stringify(JSON.parse(jsonStr)) : null;
  } catch (error) {
    isValid = false;
  }
  return { isValid, compressionJson };
};

// 根据某属性去重
export const uniqueData = (data: any, key: any) => {
  return Array.from(new Map(data.map((item: any) => [item[key], item])).values());
};

export const chunkArray = (array: any, chunkSize: number) => {
  const chunks = [];
  for (let i = 0; i < array.length; i += chunkSize) {
    chunks.push(array.slice(i, i + chunkSize));
  }
  return chunks;
};

export const dateTimeFormater = (date: any) => {
  const today = new Date(date);

  let dd = today.getDate();
  let mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();
  let hh = today.getHours();
  let ms = today.getMinutes();
  let ss = today.getSeconds();

  dd = ppo.fill0(dd);
  mm = ppo.fill0(mm);
  hh = ppo.fill0(hh);
  ms = ppo.fill0(ms);
  ss = ppo.fill0(ss);

  const d1 = '-';
  const d2 = ':';

  return `${yyyy + d1 + mm + d1 + dd} ${hh}${d2}${ms}${d2}${ss}`;
};

export const API_HOST = window.location.host.indexOf('localhost') !== -1 ? 'http://localhost:3000' : '';

// 父数组排序，在子数组中的排前面，不在子数组的排后面
export const customSort = (arr: any[], subarr: any[], key: any = '') => {
  const subarrSet = new Set(subarr);
  return arr.sort((a, b) => {
    const aVal = key ? a[key] : a;
    const bVal = key ? b[key] : b;
    const inA = subarrSet.has(aVal);
    const inB = subarrSet.has(bVal);
    // 如果a、b都在subarr，则按原来顺序
    return inA === inB ? (subarr.indexOf(aVal) < subarr.indexOf(bVal) ? -1 : 1) : inA ? -1 : 1;
  });
};
// 根据父数组的顺序对子数组进行排序
export const sortedChildArray = (childArray: any, parentArray: any) => {
  const result = childArray.sort((a: any, b: any) => {
    return parentArray.indexOf(a) - parentArray.indexOf(b);
  });
  return result;
};
export const insertArray = (array1: any, index: any, direction: any, array2: any) => {
  // array1：原始数组。
  // index：要插入的位置。
  // direction：插入方向（"left" 或 "right"）。
  // array2：要插入的数组。
  // 确保索引在有效范围内
  if (index > array1.length) {
    throw new Error('Index out of bounds');
  }

  // 创建一个新的数组以避免修改原数组
  const newArray = [...array1];

  if (direction === 'left') {
    // 在指定位置的左边插入 array2 的元素
    newArray.splice(index, 0, ...array2);
  } else if (direction === 'right') {
    // 在指定位置的右边插入 array2 的元素
    newArray.splice(index + 1, 0, ...array2);
  } else {
    throw new Error("Direction must be 'left' or 'right'");
  }

  return newArray;
};

// key相同的元素是否连续出现在数组中
export const areKeysContinuous = (arr: any, key: any) => {
  const keyPositions: any = {};
  // 收集每个key的下标
  arr.forEach((item: any, index: any) => {
    if (!keyPositions[item[key]]) {
      keyPositions[item[key]] = [];
    }
    keyPositions[item[key]].push(index);
  });
  // 检查每个key的下标是否连续
  for (const positions of Object.values(keyPositions) as any) {
    for (let i = 1; i < positions?.length; i++) {
      if (positions[i] !== positions?.[i - 1] + 1) {
        return false;
      }
    }
  }
  return true;
};
