import { createModel } from '@rematch/core';
import { cloneDeep } from 'lodash';
import { get, post } from 'utils/http';
import { DEFAULT_PROCESS_INFO } from 'constants/corpus-upgraded';

export default createModel()({
  state: {
    projectList: [],                                      // 项目列表
    templateList: [],                                     // 模板列表
    processInfos: [cloneDeep(DEFAULT_PROCESS_INFO)],      // 流程信息
    processIndex: '0',                                    // 当前选中的流程tab下标
    IsNew: false,                                         // 当前选中的tab是否是新建或复制的
    userList: [],                                         // 用户列表
    taskList: [],                                         // 任务列表
    qaList: [],                                           // 任务列表
  },
  reducers: {
    setState: (state: any, payload: any) => ({ ...state, ...payload }),
  },
  effects: {
    setReduxData({ key, data }) {
      this.setState({
        [key]: data,
      });
    },
    setQueryParams(data) {
      this.setState({
        queryParams: data,
      });
    },
    // 关闭弹窗时，清空设置到redux的步骤信息
    clearProcessRedux() {
      this.setState({
        processInfos: [cloneDeep(DEFAULT_PROCESS_INFO)],      // 流程信息
        processIndex: '0',                                    // 当前选中的流程tab下标
        IsNew: false,
      });
    },
    // 拉取项目列表
    async getProjectList(data = {}) {
      const rst = await get('/api/corpusUpgraded/getProjectList', data);
      if (rst?.rows?.length > 0) {
        this.setState({
          projectList: rst.rows || [],
        });
      }
      return rst || {};
    },
    // 创建项目
    async createProject(data = {}) {
      const rst = await post('/api/corpusUpgraded/createProject', data);
      return rst;
    },
    // 删除项目
    async deleteProject(data = {}) {
      const rst = await post('/api/corpusUpgraded/deleteProject', data);
      return rst;
    },
    // 修改项目
    async updateProject(data = {}) {
      const rst = await post('/api/corpusUpgraded/updateProject', data);
      return rst;
    },
    // 拉取模板列表
    async getTemplateList(data = {}) {
      const rst = await get('/api/corpusUpgraded/getTemplateList', data);
      if (rst?.rows?.length > 0) {
        this.setState({
          templateList: rst.rows || [],
        });
      }
      return rst || [];
    },
    // 基于ID拉取项目信息+模板信息
    async getProjectInfo(data = {}) {
      const rst = await post('/api/corpusUpgraded/getProjectInfo', data);
      if (rst?.projectInfoMap) {
        return rst?.projectInfoMap;
      }
      return null;
    },
    // 创建模板
    async createTemplate(data = {}) {
      const rst = await post('/api/corpusUpgraded/createTemplate', data);
      return rst;
    },
    // 修改模板
    async updateTemplate(data = {}) {
      const rst = await post('/api/corpusUpgraded/updateTemplate', data);
      return rst;
    },
    // 获取用户列表
    async getUserList(data = {}) {
      const rst = await get('/api/corpusUpgraded/getUserList', data);
      if (rst?.length > 0) {
        this.setState({
          userList: rst || [],
        });
      }
      return rst || {};
    },
    // 拉取任务列表
    async getTaskList(data = {}) {
      const rst = await post('/api/corpusUpgraded/getTaskList', data);
      if (rst?.rows?.length > 0) {
        this.setState({
          taskList: rst.rows || [],
        });
      }
      return rst || {};
    },
    // 删除任务
    async delTask(data = {}) {
      const rst = await post('/api/corpusUpgraded/delTask', data);
      return rst;
    },
    // 新增任务
    async createTask(data = {}) {
      const rst = await post('/api/corpusUpgraded/createTask', data);
      return rst;
    },
    // 新建qa
    async createQa(data = {}) {
      const rst = await post('/api/corpusUpgraded/createQa', data);
      return rst;
    },
    // 修改qa
    async updateQa(data = {}) {
      const rst = await post('/api/corpusUpgraded/updateQa', data);
      return rst;
    },
    // 根据taskId获取qa列表
    async getQaListByTaskId(data = {}) {
      const rst = await post('/api/corpusUpgraded/getQaListByTaskId', data);
      return rst;
    },
    // 批量删除qa
    async deleteQaBatch(data = {}) {
      const rst = await post('/api/corpusUpgraded/deleteQaBatch', data);
      return rst;
    },
    // 根据任务Id入库语料
    async storageCorpusByTaskId(data = {}) {
      const rst = await post('/api/corpusUpgraded/storageCorpusByTaskId', data);
      return rst;
    },
  },
});

